import { Button, Input } from '@missionlabs/smartagent-app-components'
import { Loader } from 'components'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { useEffect, useState } from 'react'
import { H } from 'react-accessible-headings'
import { useDispatch, useSelector } from 'react-redux'
import { selectApp } from 'store/app/app.selectors'
import { selectContact } from 'store/contact/contact.selectors'
import { clearMeeting, selectMeeting, setMeetingInvitedSMS } from 'store/videoMeeting/videoMeeting.reducer'

export const SendInvite = () => {
    const dispatch = useDispatch();
    const contact = useSelector(selectContact);
    const videoMeeting = useSelector(selectMeeting);
    const app = useSelector(selectApp);
    const [number, setNumber] = useState<string>();

    useEffect(() => {
        setNumber(`+${contact?.customerEndpointAddress}`);
    }, []);

    const handleSend = async () => {
        if(videoMeeting.meeting?.MeetingId && number) {
                dispatch(setMeetingInvitedSMS({smsInviteSentTo: number ?? '', meetingID: videoMeeting.meeting.MeetingId ?? '', brand: app.ID}));
        }
    };

    const handleCancel = async () => {
        if(videoMeeting.meeting?.MeetingId) {
                dispatch(clearMeeting({meetingID: videoMeeting.meeting.MeetingId}));
        }
    };

    return (
        <div>
            <H style={{marginBottom: "5px"}}>Send video sharing request</H>
            <p style={{marginBottom: "10px"}}>We will send an SMS with a link to the number below so they can start sharing their screen</p>
            <Input
                label="Customer Number"
                title="Customer Number"
                value={number ?? ''}
                style={{width:"250px", marginBottom: "5px"}}
                onChange={setNumber}
                onValidate={(e) =>
                    !isValidPhoneNumber(e)
                        ? 'Please enter a valid phone number. Country code is required with leading +'
                        : ''
                }
                required
                showError
            />
            <div style={{display: 'flex', flexDirection: 'row'}}>
            <Button
                styling="white"
                title="Cancel Meeting"
                style={{marginRight: "10px"}}
                elastic
                onClick={handleCancel}
                disabled={videoMeeting.deleteLoading}
            >
                {videoMeeting.deleteLoading ? 
                <Loader size={16} color="#000" loaderType="clip" className="sm-pad" /> :
                'Cancel'}
            </Button>
            <Button
                styling="primary"
                title="Send Request"
                elastic
                onClick={handleSend}
                disabled={!isValidPhoneNumber(number ?? '') || videoMeeting.inviteLoading}
            >
                {videoMeeting.inviteLoading ? 
                <Loader size={16} color="#fff" loaderType="clip" className="sm-pad" /> :
                'Send Request'}
            </Button>
            </div>
        </div>
    )
}
