import { Meeting } from "@aws-sdk/client-connect"
import { Attendee } from "amazon-chime-sdk-js"

export type VideoMeetingState = {
    loadingStatus: VideoMeetingLoadingStatus
    meeting: Meeting | null
    agentAttendee: Attendee | null
    contactID: string | null
    smsInviteSentTo: string | null
    inviteLoading: boolean
    deleteLoading: boolean
}

export enum VideoMeetingLoadingStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface CreateMeetingAction {
    contactID: string
}

export interface ClearMeetingAction {
    meetingID: string
}

export interface SetMeetingInvitedSMSAction {
    brand: string
    smsInviteSentTo: string
    meetingID: string
}

export interface SetMeetingReqLoadingAction {
    inviteLoading?: boolean
    deleteLoading?: boolean
}

export interface CreateMeetingSuccessAction {
    agentAttendee: Attendee
    meeting: Meeting
}

export interface CreateMeetingFailedAction {
    message?: string
    header?: string
}
