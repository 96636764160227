import { Middleware } from '@reduxjs/toolkit'
import * as videoMeetingAPI from 'services/api/api.videoMeeting'
import { showNotification } from 'store/global/global.reducer'
import RootState from 'store/state'
import {
    clearMeeting,
    createMeeting,
    createMeetingFailed,
    createMeetingStart,
    createMeetingSuccess,
    setMeetingInvitedSMS,
    setMeetingReqLoading,
} from 'store/videoMeeting/videoMeeting.reducer'
import { isAnyAction } from 'utils'

const GENERIC_ERROR_MESSAGE = 'An unknown error occured.'
const GENERIC_ERROR_HEADER = 'Video meeting error'

const videoMeetingMiddleware: Middleware<{}, RootState> = (store) => (next) => async (action) => {
    if (!isAnyAction(action)) return

    const {
        app: { ID, instance = { ID: '' } },
        auth: { token = '' },
    } = store.getState()

    switch (action.type) {
        case createMeeting.type: {
            try {
                store.dispatch({ type: createMeetingStart.type })

                const response = await videoMeetingAPI.createMeeting({
                    ...action.payload,
                    companyID: ID,
                    instanceID: instance?.ID,
                    token,
                })

                store.dispatch(createMeetingSuccess(response))
            } catch (error) {
                console.error('Error creating meeting', error)
                store.dispatch(
                    createMeetingFailed({
                        header: 'Error creating video meeting',
                        message: error.message,
                    }),
                )
            }
            return next(action)
        }
        case setMeetingInvitedSMS.type: {
            try {
                store.dispatch(setMeetingReqLoading({ inviteLoading: true }))

                const response = await videoMeetingAPI.sendMeetingInvite({
                    ...action.payload,
                    customerEndpointAddress: action.payload.smsInviteSentTo,
                    companyID: ID,
                    instanceID: instance?.ID,
                    token,
                })

                if(response.status !== 200) throw new Error(response.statusText)
                store.dispatch(setMeetingReqLoading({ inviteLoading: false }))
                return next(action)
            } catch (error) {
                store.dispatch(
                    createMeetingFailed({
                        header: 'Failed to send invite',
                        message: error.message,
                    }),
                )
            }
            store.dispatch(setMeetingReqLoading({ inviteLoading: false }))
            return
        }
        case clearMeeting.type: {
            try {
                store.dispatch(setMeetingReqLoading({ deleteLoading: true }))

                const response = await videoMeetingAPI.deleteMeeting({
                    ...action.payload,
                    companyID: ID,
                    instanceID: instance?.ID,
                    token,
                })
                if(response.status !== 200) throw new Error(response.statusText)
                store.dispatch(setMeetingReqLoading({ deleteLoading: false }))
                return next(action)
            } catch (error) {
                store.dispatch(
                    createMeetingFailed({
                        header: 'Failed to cancel meeting',
                        message: error.message,
                    }),
                )
            }
            store.dispatch(setMeetingReqLoading({ deleteLoading: false }))
            return
        }
        case createMeetingFailed.type: {
            store.dispatch({
                type: showNotification.type,
                payload: {
                    type: 'error',
                    header: action.payload.header ?? GENERIC_ERROR_HEADER,
                    message: action.payload.message ?? GENERIC_ERROR_MESSAGE,
                },
            })
            return next(action)
        }
    }

    return next(action)
}
export default videoMeetingMiddleware
