import { Button } from '@missionlabs/smartagent-app-components'
import {
    LocalVideoProvider,
    MeetingProvider,
    useMeetingManager,
    useRosterState,
} from 'amazon-chime-sdk-component-library-react'
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js'
import { Loader } from 'components'
import { useEffect, useState } from 'react'
import { H } from 'react-accessible-headings'
import { useDispatch, useSelector } from 'react-redux'
import { selectContact } from 'store/contact/contact.selectors'
import { clearMeeting, selectMeeting } from 'store/videoMeeting/videoMeeting.reducer'
import styles from './index.module.scss'

export const VideoSession = () => {
    return (
        <div>
            <MeetingProvider>
                <LocalVideoProvider>
                    <VideoSessionWrapped />
                </LocalVideoProvider>
            </MeetingProvider>
        </div>
    )
}

const VideoSessionWrapped = () => {
    const dispatch = useDispatch()
    const contact = useSelector(selectContact)
    const videoMeeting = useSelector(selectMeeting)
    const meetingManager = useMeetingManager()
    const { roster } = useRosterState()
    const [customerJoined, setCustomerJoined] = useState<boolean>(false)

    const joinMeeting = async () => {
        const meetingSessionConfiguration = new MeetingSessionConfiguration(
            videoMeeting.meeting,
            videoMeeting.agentAttendee,
        )
        await meetingManager.join(meetingSessionConfiguration)
        await meetingManager.start()
    }

    const handleCancel = async () => {
        if (videoMeeting.meeting?.MeetingId) {
            dispatch(clearMeeting({ meetingID: videoMeeting.meeting.MeetingId }))
        }
    }

    useEffect(() => {
        joinMeeting()
    }, [])

    useEffect(() => {
        Object.keys(roster).forEach((key) => {
            if (roster[key].externalUserId === `customer-${contact?.ID}`) {
                console.log('Customer Joined')
                setCustomerJoined(true)
            }
        })
    }, [roster])

    if (customerJoined) {
        return (
            <>
                Active Session
            </>
        )
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.loader}>
                    <Loader size={64} color="#000" loaderType="clip" />
                </div>
                <H data-testid={'waiting-header'}>
                    Waiting for {videoMeeting.smsInviteSentTo} to join...
                </H>
                <Button
                    styling="red"
                    title="Cancel Session"
                    style={{ width: '130px' }}
                    elastic
                    onClick={handleCancel}
                    disabled={videoMeeting.deleteLoading}
                >
                    {videoMeeting.deleteLoading ? (
                        <Loader size={16} color="#fff" loaderType="clip" className="sm-pad" />
                    ) : (
                        'Cancel Session'
                    )}
                </Button>
            </div>
        )
    }
}
