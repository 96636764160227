import { Box } from '@missionlabs/smartagent-app-components'
import { H } from 'react-accessible-headings'
import { useSelector } from 'react-redux'
import { selectIsMeetingCreated, selectMeeting } from 'store/videoMeeting/videoMeeting.reducer'
import { CreateSession } from './CreateSession'

import { SendInvite } from './SendInvite'
import { VideoSession } from './VideoSession'
import styles from './index.module.scss'

export const VideoSharingRoot = () => {
    const isMeetingCreated = useSelector(selectIsMeetingCreated)
    const videoMeeting = useSelector(selectMeeting)

    const elementToRender = () => {
        if (videoMeeting.smsInviteSentTo) return <VideoSession />
        else if (isMeetingCreated) return <SendInvite />
        else return <CreateSession />
    }

    return (
        <Box alt collapse boxLabel="Video Sharing" header={<H>Video Sharing</H>}>
            <div className={styles.videoMeetingContainer}>{elementToRender()}</div>
        </Box>
    )
}
