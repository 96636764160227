import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    ClearMeetingAction,
    CreateMeetingAction,
    CreateMeetingFailedAction,
    CreateMeetingSuccessAction,
    SetMeetingInvitedSMSAction,
    SetMeetingReqLoadingAction,
    VideoMeetingLoadingStatus,
    VideoMeetingState,
} from './videoMeeting.state'

const initialState: VideoMeetingState = {
    loadingStatus: VideoMeetingLoadingStatus.IDLE,
    meeting: null,
    agentAttendee: null,
    contactID: null,
    smsInviteSentTo: null,
    deleteLoading: false,
    inviteLoading: false
}

export const videoMeetingSlice = createSlice({
    name: 'videoMeeting',
    initialState,
    reducers: {
        createMeeting(state: VideoMeetingState, action: PayloadAction<CreateMeetingAction>) {
            return {
                ...state,
                contactID: action.payload.contactID,
            }
        },
        createMeetingStart(state: VideoMeetingState) {
            return {
                ...state,
                loadingStatus: VideoMeetingLoadingStatus.LOADING,
            }
        },
        createMeetingSuccess(
            state: VideoMeetingState,
            action: PayloadAction<CreateMeetingSuccessAction>,
        ) {
            return {
                ...state,
                loadingStatus: VideoMeetingLoadingStatus.IDLE,
                ...action.payload
            }
        },
        createMeetingFailed(
            state: VideoMeetingState,
            action: PayloadAction<CreateMeetingFailedAction>,
        ) {
            return {
                ...state,
                loadingStatus: VideoMeetingLoadingStatus.ERROR,
                contactID: null,
                smsInviteSentTo: null,
                errorMessage: action.payload.message,
            }
        },
        setMeetingInvitedSMS(state: VideoMeetingState, action: PayloadAction<SetMeetingInvitedSMSAction>) {
            return {
                ...state,
                smsInviteSentTo: action.payload.smsInviteSentTo,
            }
        },
        setMeetingReqLoading(state: VideoMeetingState, action: PayloadAction<SetMeetingReqLoadingAction>) {
            return {
                ...state,
                inviteLoading: !!action.payload.inviteLoading,
                deleteLoading: !!action.payload.deleteLoading,
            }
        },
        clearMeeting(_state, _action: PayloadAction<ClearMeetingAction>) {
            return initialState
        },
    },
    selectors: {
        selectIsMeetingCreated: (state: VideoMeetingState) => {
            return state.meeting && state.contactID
        },
        selectMeeting: (state: VideoMeetingState) => {
            return state
        },
    },
})

export const { createMeeting, createMeetingStart, createMeetingSuccess, createMeetingFailed, setMeetingInvitedSMS, clearMeeting, setMeetingReqLoading} =
    videoMeetingSlice.actions

export const { selectIsMeetingCreated, selectMeeting } = videoMeetingSlice.selectors

export type VideoMeetingAction = ReturnType<
    (typeof videoMeetingSlice.actions)[keyof typeof videoMeetingSlice.actions]
>

export default videoMeetingSlice.reducer
